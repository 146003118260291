<template>
  <div class="about">
    <div class="title">
      怎样在碎片化的时间内最大化的提高学习效率？<em @click="this.$router.push('/')">来自 计算机二级证书</em>
    </div>
    <div class="content">
      <p>利用碎片化时间提高自己学习效率，首先整理碎片时间知道自己有哪些碎片时间，其次合理利用不一样时长的碎片时间进行不同方式的学习，最后遵循遗忘规律进行复习来提高自己学习效率。</p>
      <p>利用碎片化时间提高自己学习效率，首先整理碎片时间知道自己有哪些碎片时间。想要利用碎片化时间去学习，一定先清楚自己有哪些碎片时间。比如上学的人碎片化时间比较多，因为行动相对自由，没有课的时间都可以自由支配算是碎片化时间，早起时间可以充分利用起来，还有晚上大家可能都在玩手机打游戏你可以利用睡前几个小时进行学习。上班的人碎片时间非常零散，比如早晚上下班可以在公交地铁进行知识识记，中午午休时间也可以拿来学习，工作时间不忙抽空出来学习。所以要整理自己大概的碎片时间好做到心中有数能更好安排时间进行不同方式的学习。</p>
      <p>其次合理利用不同时长的碎片时间进行不同方式的学习。当你的时间比较长的时候可以看看网络课视频课，还能做笔记这样能理解学习内容提供学习效率。如果只有零散的时间，可以找出音频来听或者背知识点看看讲解都能提高学习效率。下班后或者下课之后的时间比较长，这段时间一定要充分利用起来去进行学习，这是相对比较长的时间要好好利用把今天的内容整理，完成一天的学习内容。</p>
      <p>最后遵循遗忘规律进行复习来提高自己学习效率。艾宾浩斯遗忘曲线是先快后慢，所以学习的内容一定要及时复习最后才会牢牢记在你的脑子里。每天早上或者上午挤公交地铁这样碎片时间用来对前一天学习的知识进行复习，每天都是这样日复一日学习效率自然就提高了。</p>
      <p>小伙伴们，请问有没有解决你的燃眉之急呢？</p>
    </div>
    <div class="praise">
      <div class="icon">
        <img src="../assets/images/icon1.png" />
      </div>
      <div class="num">9人点赞</div>
      <div>
        <ul>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
          <li><img src="../assets/images/user.png" /></li>
        </ul>
      </div>
    </div>
    <div class="name">一朵小红花   发布 09-27 19:51  阅读 4330</div>
    <Comment />
  </div>
</template>


<script>
import Comment from '../components/Comment.vue'
export default {
  name: 'Detail',
  components: {
    Comment
  }
}
</script>

<style lang="scss" scoped>
  .about {
    width: 1000px;
    margin: 16px auto 0 auto;
    background: #ffffff;
    padding: 38px 58px;
    box-sizing: border-box;
    .title {
      font-size: 28px;
      line-height: 1.389;
      font-weight: 600;
      color: #262626;
      em {
        font-style: normal;
        cursor: pointer;
      }
    }
    .content {
      padding-top: 12px;
      p {
        padding-top: 18px;
        font-size: 12px;
        line-height: 24px;
        white-space: break-spaces;
        word-break: break-word;
        word-wrap: break-word;
        line-height: 28px;
        letter-spacing: 0.008em;
        color: #404040;
        font-size: 15px;
        margin-top: 12px;
        display: inline-block;
      }
    }
    .praise {
      margin: 0 auto;
      padding-top: 88px;
      width: 100%;
      text-align: center;
      .icon {
        width: 20px;
        height: 20px;
        margin: 0 auto;
        cursor: pointer;
      }
      .num {
        font-size: 13px;
        margin-top: 4px;
      }
      ul {
        margin-top: 12px;
        li {
          width: 32px;
          height: 32px;
          margin: 0 4px;
          display: inline-block;
        }
      }
    }
    .name {
      color: #666;
      margin-top: 28px;
      display: inline-block;
      margin-bottom: 52px;
    }
  }
</style>