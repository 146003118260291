<template>
<div class="comment">
  <div class="wrap">
    <div class="user">
      <img src="../assets/images/user.png" />
    </div>
    <div class="text">
      <textarea />
    </div>
  </div>
  <div class="add"><img src="../assets/images/icon4.png" /><em>提交</em></div>
</div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.comment {
  background: #ffffff;
  margin-bottom: 14px;
  overflow: hidden;
  .wrap {
    margin: 0;
    display: flex;
    .user {
      width: 42px;
      height: 42px;
      margin-right: 14px;
    }
    .text {
      flex: 1;
      textarea {
        width: 100%;
        outline: none;
        border: 1px solid #eee;
        border-radius: 8px;
        min-height: 200px;
        padding: 16px;
        box-sizing: border-box;
      }
    }
  }
  .add {
    margin-top: 18px;
    float: right;
    width: 96px;
    background: #417ff9;
    color: #ffffff;
    font-size: 13px;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
    &:hover {
      background: #376fdd;
    }
    img {
      width: 18px;
      height: 18px;
      vertical-align: middle;
      margin-right: 3px;
    }
    em {
      vertical-align: middle;
      font-style: normal;
      color: #ffffff;
      font-size: 13px;
      height: 36px;
      line-height: 36px;
    }
  }
}
</style>
